import React from 'react';
import logo from './logo_cs.png';
import { HashLink as Link } from 'react-router-hash-link';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    const handleLinkClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer className="footer">
            <div className="footer-columns">
                <div className="footer-column">
                    <img src={logo} alt="Logo" className="footer-logo" />
                </div>
                
                <div className="footer-column">
                    <h4 className="footer-title">Enlaces</h4>
                    <ul className="footer-links">
                        <li><Link smooth to="/#faqs-section" className="footer-link">FAQs</Link></li>
                    </ul>
                </div>

                <div className="footer-column">
                    <h4 className="footer-title">Legal</h4>
                    <ul className="footer-links">
                    <li><Link to="/politica-de-privacidad" className="footer-link" onClick={handleLinkClick}>Política de privacidad</Link></li>
                    <li><Link to="/terminos-y-condiciones" className="footer-link" onClick={handleLinkClick}>Términos y condiciones</Link></li>
                    </ul>
                </div>
                
                <div className="footer-column">
                    <h4 className="footer-title">Contacto</h4>
                    <p className="footer-contact">contacto@generadordecontraseñas.es</p>
                </div>
            </div>
            <div className="footer-copyright">
                &copy; {currentYear} GeneradorContraseñas. Todos los derechos reservados.
            </div>
        </footer>
    );
};

export default Footer;
