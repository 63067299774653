import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Footer from './Footer';
import PolíticaDePrivacidad from './PolíticaDePrivacidad';
import TérminosYCondiciones from './TerminosYCondiciones';
import CookieConsent from './CookieConsent';

function App() {
  return (
    <Router basename="/">
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/politica-de-privacidad" element={<PolíticaDePrivacidad />} />
            <Route path="/terminos-y-condiciones" element={<TérminosYCondiciones />} />
          </Routes>
        </div>
        <Footer />
        {/* <CookieConsent /> */}
      </div>
    </Router>
  );
}

export default App;
