import React from 'react';

const TérminosYCondiciones = () => {
    return (
        <div className="terms-container">
            <h1>Términos y Condiciones</h1>
            <p>
                Bienvenido a generadordecontraseñas.es. Estos términos y condiciones describen las reglas y regulaciones para el uso del sitio web generadordecontraseñas.es.
            </p>
            <h2>1. Aceptación de los términos</h2>
            <p>
                Al acceder a este sitio web, asumimos que aceptas estos términos y condiciones en su totalidad. No continúes usando generadordecontraseñas.es si no aceptas todos los términos y condiciones establecidos en esta página.
            </p>
            <h2>2. Uso del sitio web</h2>
            <p>
                El contenido de este sitio web es solo para tu información general y uso. Está sujeto a cambios sin previo aviso.
            </p>
            <h2>3. Privacidad</h2>
            <p>
                Tu privacidad es importante para nosotros. Por favor, lee nuestra <a href="/politica-de-privacidad">Política de Privacidad</a> para entender cómo recopilamos, usamos y protegemos tu información.
            </p>
            <h2>4. Limitación de responsabilidad</h2>
            <p>
                Ni nosotros ni terceros proporcionamos ninguna garantía en cuanto a la exactitud, puntualidad, rendimiento, integridad o idoneidad de la información y los materiales encontrados u ofrecidos en este sitio web para ningún propósito particular. Reconoces que dicha información y materiales pueden contener inexactitudes o errores y excluimos expresamente la responsabilidad por tales inexactitudes o errores en la máxima medida permitida por la ley.
            </p>
            <h2>5. Enlaces a otros sitios web</h2>
            <p>
                Este sitio web puede incluir enlaces a otros sitios web de interés. Sin embargo, una vez que hayas utilizado estos enlaces para salir de nuestro sitio, debes tener en cuenta que no tenemos control sobre ese otro sitio web. Por lo tanto, no podemos responsabilizarnos de la protección y privacidad de cualquier información que proporciones mientras visitas dichos sitios y dichos sitios no se rigen por esta declaración de privacidad.
            </p>
            <h2>6. Cambios en los términos</h2>
            <p>
                Podemos actualizar estos términos y condiciones de vez en cuando. Te notificaremos de cualquier cambio publicando los nuevos términos en esta página. Se recomienda que revises esta página periódicamente para estar al tanto de cualquier cambio.
            </p>
            <h2>7. Contacto</h2>
            <p>
                Si tienes alguna pregunta sobre estos términos y condiciones, por favor contáctanos en contacto@generadordecontraseñas.es.
            </p>
            <p>
                Fecha de la última actualización: [Fecha de la última actualización]
            </p>
        </div>
    );
};

export default TérminosYCondiciones;
