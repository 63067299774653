import React from 'react';

const PolíticaDePrivacidad = () => {
    return (
        <div className="policy-container">
            <h1>Política de Privacidad</h1>
            <p>
                En generadordecontraseñas.es, valoramos tu privacidad y estamos comprometidos a proteger tu información personal. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos tu información.
            </p>
            <h2>1. Información que recopilamos</h2>
            <p>
                Podemos recopilar la siguiente información:
            </p>
            <ul>
                <li>Información de contacto, como nombre y dirección de correo electrónico.</li>
                <li>Información demográfica, como código postal, preferencias e intereses.</li>
                <li>Otra información relevante para encuestas y/o ofertas para clientes.</li>
            </ul>
            <h2>2. Uso de la información recopilada</h2>
            <p>
                Utilizamos esta información para entender tus necesidades y proporcionarte un mejor servicio, y en particular por las siguientes razones:
            </p>
            <ul>
                <li>Mantenimiento de registros internos.</li>
                <li>Mejora de nuestros productos y servicios.</li>
                <li>Envío de correos electrónicos promocionales sobre nuevos productos, ofertas especiales u otra información que pensamos que puede resultar interesante.</li>
                <li>Personalización del sitio web según tus intereses.</li>
            </ul>
            <h2>3. Seguridad</h2>
            <p>
                Nos comprometemos a garantizar que tu información esté segura. Con el fin de evitar el acceso no autorizado o la divulgación, hemos puesto en marcha procedimientos físicos, electrónicos y administrativos adecuados para proteger y asegurar la información que recopilamos en línea.
            </p>
            <h2>4. Cómo usamos las cookies</h2>
            <p>
                Una cookie es un pequeño archivo que solicita permiso para ser colocado en el disco duro de tu computadora. Una vez que aceptas, el archivo se agrega y la cookie ayuda a analizar el tráfico web o te permite saber cuándo visitas un sitio en particular. Las cookies permiten que las aplicaciones web te respondan como individuo. La aplicación web puede adaptar sus operaciones a tus necesidades, gustos y disgustos al reunir y recordar información sobre tus preferencias.
            </p>
            <h2>5. Enlaces a otros sitios web</h2>
            <p>
                Nuestro sitio web puede contener enlaces a otros sitios de interés. Sin embargo, una vez que has utilizado estos enlaces para salir de nuestro sitio, debes tener en cuenta que no tenemos control sobre ese otro sitio web. Por lo tanto, no podemos ser responsables de la protección y privacidad de cualquier información que proporciones mientras visitas dichos sitios y dichos sitios no se rigen por esta declaración de privacidad.
            </p>
            <h2>6. Control de tu información personal</h2>
            <p>
                Puedes optar por restringir la recopilación o el uso de tu información personal de las siguientes maneras:
            </p>
            <ul>
                <li>Cuando se te pide que rellenes un formulario en el sitio web, busca la casilla en la que puedes hacer clic para indicar que no deseas que la información sea utilizada por nadie para fines de marketing directo.</li>
                <li>Si previamente has acordado con nosotros usar tu información personal para fines de marketing directo, puedes cambiar de opinión en cualquier momento escribiéndonos o enviándonos un correo electrónico a contacto@generadordecontraseñas.es.</li>
            </ul>
            <h2>7. Contacto</h2>
            <p>
                Si tienes alguna pregunta sobre esta política de privacidad, por favor contáctanos en contacto@generadordecontraseñas.es.
            </p>

        </div>
    );
};

export default PolíticaDePrivacidad;
