import React from 'react';
import reloadIcon from './icons/arrow-reload-02-svgrepo-com.svg';
import copyIcon from './icons/copy-svgrepo-com.svg';

const PasswordDisplay = ({ password, generatePassword, handleCheckboxChange, options, passwordLength, handleSliderChange, sliderRef }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(password);
  };

  return (
    <div className="password-display">
      <h2>Contraseña</h2>
      <div className="password-box">
        <span className="password-text">{password || 'Genera una contraseña para verla aquí'}</span>
        <div className="button-container">
          <button className="generate-button" onClick={generatePassword}>
            <img src={reloadIcon} alt="Reload Icon" />
          </button>
          <button className="copy-button" onClick={copyToClipboard}>
            <img src={copyIcon} alt="Copy Icon" />
          </button>
        </div>
      </div>
      <h2>Personalizar contraseña</h2>
      <div className="customize-options">
        <label>
          <input
            type="checkbox"
            name="includeUppercase"
            checked={options.includeUppercase}
            onChange={handleCheckboxChange}
          />
          <span></span>
          Mayúsculas
        </label>
        <label>
          <input
            type="checkbox"
            name="includeLowercase"
            checked={options.includeLowercase}
            onChange={handleCheckboxChange}
          />
          <span></span>
          Minúsculas
        </label>
        <label>
          <input
            type="checkbox"
            name="includeNumbers"
            checked={options.includeNumbers}
            onChange={handleCheckboxChange}
          />
          <span></span>
          Números
        </label>
        <label>
          <input
            type="checkbox"
            name="includeSymbols"
            checked={options.includeSymbols}
            onChange={handleCheckboxChange}
          />
          <span></span>
          Símbolos
        </label>
      </div>
      <div className="slider-container">
        <label>
          Longitud de la contraseña: {passwordLength}
          <input
            type="range"
            min="1"
            max="50"
            value={passwordLength}
            onChange={handleSliderChange}
            ref={sliderRef}
          />
        </label>
      </div>
    </div>
  );
};

export default PasswordDisplay;