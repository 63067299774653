import React, { useState } from 'react';

const faqs = [
    {
        question: "¿Es seguro utilizar este generador de contraseñas en línea?",
        answer: "Sí, nuestro generador de contraseñas utiliza algoritmos robustos para garantizar que las contraseñas generadas son seguras y aleatorias. Además, no almacenamos ninguna de las contraseñas generadas en nuestros servidores."
    },
    {
        question: "¿Por qué es importante usar un generador de contraseñas?",
        answer: "Usar un generador de contraseñas asegura que tus contraseñas sean complejas y difíciles de adivinar. Las contraseñas generadas aleatoriamente son menos susceptibles a ataques de fuerza bruta y otras técnicas de hacking."
    },
    {
        question: "¿Debo tener una contraseña diferente para cada cuenta?",
        answer: "Sí, es altamente recomendable usar una contraseña única para cada cuenta. Esto minimiza el riesgo de que, si una de tus cuentas es comprometida, las demás permanezcan seguras."
    },
    {
        question: "¿Cuáles son algunas contraseñas que debería evitar?",
        answer: "Debes evitar contraseñas comunes como \"123456\", \"password\", \"qwerty\", \"111111\" y \"abc123\". Estas son fáciles de adivinar y están entre las primeras que los hackers prueban."
    },
    {
        question: "¿Qué características hacen que un generador de contraseñas sea efectivo?",
        answer: "Un buen generador de contraseñas debe ofrecer opciones para incluir letras mayúsculas, minúsculas, números y símbolos. Además, debería permitirte ajustar la longitud de la contraseña."
    },
    {
        question: "¿Qué requisitos debe cumplir una contraseña para ser considerada segura?",
        answer: "Una contraseña segura debe tener al menos 12 caracteres e incluir una combinación de letras mayúsculas y minúsculas, números y símbolos. Además, debe evitar palabras comunes y patrones predecibles."
    },
    {
        question: "¿Cómo puedo generar una contraseña completamente aleatoria?",
        answer: "Puedes generar una contraseña aleatoria utilizando nuestro generador en línea. Simplemente ajusta las opciones según tus preferencias y presiona el botón de generar para obtener una contraseña segura al instante."
    },
    {
        question: "¿Cómo puedo almacenar mis contraseñas de forma segura?",
        answer: "La mejor manera de almacenar tus contraseñas es usando un administrador de contraseñas confiable. Estos programas encriptan tus contraseñas y te permiten acceder a ellas de manera segura."
    },
    {
        question: "¿Qué factores hacen que una contraseña sea segura?",
        answer: "Una contraseña segura debe ser lo suficientemente larga y compleja para resistir ataques. Debe incluir una combinación de caracteres variados y evitar cualquier información personal o palabras del diccionario."
    }
];

function FAQ() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <h2>Preguntas Frecuentes</h2>
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                    <div className="faq-question" onClick={() => toggleFAQ(index)}>
                        {faq.question}
                    </div>
                    {activeIndex === index && (
                        <div className="faq-answer">
                            {faq.answer}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default FAQ;
