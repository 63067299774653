import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logo from './logo_cs.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar-content">
      <nav className="navbar">
        <Link to="/" className="logo-link">
          <img src={logo} alt="Logo" className="logo" />
        </Link>

        <div className={`links ${isOpen ? 'open' : ''}`}>
          <div className="header-buttons">
            <Link smooth to="/#faqs-section" className="faq-link">FAQs</Link>
          </div>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`hamburger ${isOpen ? 'open' : ''}`}></div>
          <div className={`hamburger ${isOpen ? 'open' : ''}`}></div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
