import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import PasswordDisplay from './PasswordDisplay';
import Menu from "./Menu";
import FAQ from "./FAQs";

function Home() {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [options, setOptions] = useState({
        includeUppercase: true,
        includeLowercase: true,
        includeNumbers: true,
        includeSymbols: true,
    });
    const [passwordLength, setPasswordLength] = useState(12);
    const sliderRef = useRef(null);

    useEffect(() => {
        generatePassword();
        updateSliderBackground();
    }, [options, passwordLength]);

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        const selectedOptions = Object.values(options).filter(Boolean).length;

        if (!checked && selectedOptions === 1) {
            return;
        }

        setOptions({ ...options, [name]: checked });
    };

    const handleSliderChange = (e) => {
        setPasswordLength(Number(e.target.value));
        updateSliderBackground(e.target);
    };

    const updateSliderBackground = (slider) => {
        const value = ((slider || sliderRef.current).value - 1) / (50 - 1) * 100;
        (slider || sliderRef.current).style.setProperty('--value', `${value}%`);
    };

    const generatePassword = () => {
        let charset = '';
        if (options.includeUppercase) charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (options.includeLowercase) charset += 'abcdefghijklmnopqrstuvwxyz';
        if (options.includeNumbers) charset += '0123456789';
        if (options.includeSymbols) charset += '!@#$%^&*()_+~`|}{[]:;?><,./-=';
        let generatedPassword = '';
        for (let i = 0; i < passwordLength; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            generatedPassword += charset[randomIndex];
        }
        setPassword(generatedPassword);
    };

    return (
        <div className="home">
            <h1>¿Necesitas una contraseña <br /> única y<span className="titulo-destacado"> segura?</span></h1>
            <p>Crea una contraseña segura y al azar de forma instantánea con nuestra herramienta en línea.</p>
            <PasswordDisplay
                password={password}
                generatePassword={generatePassword}
                handleCheckboxChange={handleCheckboxChange}
                options={options}
                passwordLength={passwordLength}
                handleSliderChange={handleSliderChange}
                sliderRef={sliderRef}
            />
            <Menu />
            <div id="faqs-section">
                <FAQ />
            </div>
        </div>
    );
}

export default Home;
