
import icon1 from './icons/calendar-check-svgrepo-com.svg';
import icon2 from './icons/calendar-check-svgrepo-com.svg';
import icon3 from './icons/calendar-check-svgrepo-com.svg';


function Menu() {

    return (
        <div className="menu">
            <div className="menu2">
                <div className="menu2-content">
                    <h2>¿Cómo debe ser una contraseña?</h2>
                    <div className="menu2-content-container">
                        <div className="menu2-content-container-column">
                            <img src={icon1} alt="icon1" className="sellcar_icon" />
                            <h3>Larga</h3>
                            <p>Una contraseña larga es más difícil de descifrar. Debe tener al menos 12 caracteres para aumentar la seguridad.</p>
                        </div>
                        <div className="menu2-content-container-column">
                            <img src={icon2} alt="icon1" className="sellcar_icon" />
                            <h3>Compleja</h3>
                            <p>Una contraseña compleja incluye una combinación de letras mayúsculas y minúsculas, números y símbolos.</p>
                        </div>
                        <div className="menu2-content-container-column">
                            <img src={icon3} alt="icon1" className="sellcar_icon" />
                            <h3>Unica</h3>
                            <p>Usa una contraseña diferente para cada cuenta. Esto previene que un acceso no autorizado a una cuenta comprometa otras.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default Menu;
 